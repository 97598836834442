<template>
	<div>
		<NavigationBar></NavigationBar>
		<div class="banner">
			<div class="box">
				<div class="banner_name">产品展示</div>
			</div>
		</div>
		<breadNav :tabList='tabList' @tabClick='tabClick'></breadNav>
		<div v-if="!category_id">
			<div class="newshow_name box">{{dates.title}}</div>
			<div class="newshow_zx box divColumnCenter">
				<div class="newshow_timer">发布时间：{{dates.show_time}}</div>
				<!-- <div class="newshow_shu">&nbsp;&nbsp;|&nbsp;&nbsp;</div>
				<div class="newshow_follow">阅读量：<b style="font-weight:500;">301</b></div> -->
			</div>
			
			<div class="about_con box">
				<div v-html="content"></div>
			</div>
		</div>
		<div v-else>
			<div class="box">
				<div class="n_pinpai_title">产品展示</div>
				<div class="n_pinpai_list">
					<ul class="divColumnCenter">
						<li v-for="(item, index) in list" :key="index" @click="del(item.id)">
							<div class="n_pinpai_li">
								<div class="n_pinpai_li_img">
									<img :src="item.image" />
								</div>
							</div>
							<div class="n_pinpai_li_con">
								<div class="n_pinpai_li_name">{{item.title}}</div>
								<div class="n_pinpai_li_js">{{item.description}}</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		
		<foot></foot>
	</div>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue'
	import foot from '@/components/foot.vue'
	import breadNav from '@/components/breadNav.vue'
	export default {
		components: {
			NavigationBar,
			foot,
			breadNav

		},
		data() {
			return {
				tabList: ['企业简介', '组织架构', '企业文化', '资质荣誉'],
				id: '',
				content: '',
				dates: {},
				category_id:'',
				list:[]
			}
		},
		mounted() {
			console.log(this.$route)
			this.id = this.$route.query.id;
			this.category_id=this.$route.query.category_id
			if(!this.category_id){
				this.getData()
			}else{
				this.getList()
			}
			
		},
		methods: {
			tabClick(id) {
				this.category_id =  id;
				this.id = '';
				this.getList()
			},
			del(id){
				this.id =  id;
				this.category_id = '';
				this.getData()
			},
			getList(){
				let _this = this;
				let urls =
					"api/article/index?category_id=" + _this.category_id
				_this.post(urls, res => {
					_this.list=res.data.list;
					_this.tabList=res.data.category_list
					console.log(_this.list)
					
				})
			},
			getData() {
				let _this = this;
				let urls =
					"api/article/detail?id=" + _this.id
				_this.post(urls, res => {
					console.log(res.data)
					_this.dates = res.data.info;
					_this.content = res.data.info.content;
					_this.tabList=res.data.category_list
				})
			},
		}
	}
</script>

<style scoped>
	.n_pinpai_title {
		    margin-top: 50px;
		    font-size: 35px;
		    color: #c9151e;
		    text-align: center;
	}
	.n_pinpai_list {
	    margin-top: 40px;
	    padding-bottom: 50px;
	}
	.n_pinpai_list ul {
		flex-wrap: wrap;
	}
	.n_pinpai_list li {
	    width: 394px;
		height: 394px;
	    margin-right: 5px;
	    -webkit-transition: all 0.4s;
	    -moz-transition: all 0.4s;
	    -ms-transition: all 0.4s;
	    -o-transition: all 0.4s;
	    transition: all 0.4s;
		margin-bottom: 20px;
	}
	.n_pinpai_list li:hover img{
		transform: scale(1.2);
	}
	.n_pinpai_list li:hover .n_pinpai_li_con {
		color: #fff;
		background-color: #da2d2d;
	}
	.n_pinpai_li_img {
	    overflow: hidden;
	}
	.n_pinpai_li_img img {
	    max-width: 100%;
		max-height: 272px;
	    -webkit-transform: scale(1);
	    -moz-transform: scale(1);
	    -ms-transform: scale(1);
	    -o-transform: scale(1);
	    transform: scale(1);
	    -webkit-transition: all 0.8s;
	    -moz-transition: all 0.8s;
	    -ms-transition: all 0.8s;
	    -o-transition: all 0.8s;
	    transition: all 0.8s;
	}
	.n_pinpai_li_con {
	    padding-bottom: 25px;
	    background-color: #f1f1f1;
	    -webkit-transition: all 0.4s;
	    -moz-transition: all 0.4s;
	    -ms-transition: all 0.4s;
	    -o-transition: all 0.4s;
	    transition: all 0.4s;
		 color: #555555;
	}
	.n_pinpai_li_name {
	    text-align: center;
	    padding-top: 24px;
	    font-size: 22px;
	   
	    -webkit-transition: all 0.4s;
	    -moz-transition: all 0.4s;
	    -ms-transition: all 0.4s;
	    -o-transition: all 0.4s;
	    transition: all 0.4s;
	}
	.n_pinpai_li_js {
	    width: 220px;
	    margin: 0 auto;
	    font-size: 14px;
	    line-height: 28px;
	    text-align: center;
	    margin-top: 10px;
	    -webkit-transition: all 0.4s;
	    -moz-transition: all 0.4s;
	    -ms-transition: all 0.4s;
	    -o-transition: all 0.4s;
	    transition: all 0.4s;
	}
	.banner {
		background: url('../../../public/img/top2.jpg');
		width: 100%;
		height: 340px;
	}

	.banner_name {
		padding-top: 150px;
		font-size: 44px;
		color: #474747;
		text-align: left;
	}

	.about_con {
		margin: 30px auto;
		text-align: left;
	}

	/* .about_con img {
		width: 100% ;
	} */

	.about_con p {
		font-size: 15px!important;
		line-height: 1.6;
		color: #525252!important;
		margin-bottom: 25px!important;
	}
	.newshow_name {
		font-size: 28px;
		color: #333333;
		font-weight: bold;
		line-height: 30px;
		margin-top: 50px;
		text-align: left;
	}
	.newshow_zx {
		margin-top: 20px;
		text-align: left;
	}
	.newshow_zx div {
	    font-size: 14px;
	    color: #999999;
	}
	.newshow_follow {
	    margin-right: 20px;
	}
</style>