import Vue from 'vue'
import VueRouter from 'vue-router'

import index from '../views/Neverland/index.vue'
import qiye from '../views/Neverland/qiye.vue'
import xiwen from '../views/Neverland/xiwen.vue'
import chanpin from '../views/Neverland/chanpin.vue'
import xuexi from '../views/Neverland/xuexi.vue'
import xiazai from '../views/Neverland/xiazai.vue'
import gongyi from '../views/Neverland/gongyi.vue'
import lianxi from '../views/Neverland/lianxi.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'index',
		component: index
	},
	{
		path: '/qiye',
		name: 'qiye',
		component: qiye
	},
	{
		path: '/xiwen',
		name: 'xiwen',
		component: xiwen
	},
	{
		path: '/chanpin',
		name: 'chanpin',
		component: chanpin
	},
	{
		path: '/xuexi',
		name: 'xuexi',
		component: xuexi
	},
	{
		path: '/xiazai',
		name: 'xiazai',
		component: xiazai
	},
	{
		path: '/gongyi',
		name: 'gongyi',
		component: gongyi
	},
	{
		path: '/lianxi',
		name: 'lianxi',
		component: lianxi
	}
]

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes
})

export default router