<template>
	<div>
		<NavigationBar></NavigationBar>
		<div class="banner">
			<div class="box">
				<div class="banner_name">热点资讯</div>
			</div>
		</div>
		<breadNav :tabList='tabList' @tabClick='tabClick'></breadNav>
		<div v-if="!category_id">
			<div class="newshow_name box">{{dates.title}}</div>
			<div class="newshow_zx box divColumnCenter">
				<div class="newshow_timer">发布时间：{{dates.show_time}}</div>
				<!-- <div class="newshow_shu">&nbsp;&nbsp;|&nbsp;&nbsp;</div>
				<div class="newshow_follow">阅读量：<b style="font-weight:500;">301</b></div> -->
			</div>

			<div class="about_con box">
				<div v-html="content"></div>
			</div>
		</div>
		<div v-else class="n_news_ul">
			<ul class="box">
				<li v-for="(item, index) in list" :key="index" @click="del(item.id)">
					<div class="n_news_li divColumnCenter">
						<div class="n_news_li_time">
							<div class="n_news_li_nian">{{item.year}}</div>
							<div class="n_news_li_yue">{{item.mday}}</div>
							<div class="n_news_li_xian"></div>
						</div>
						<div class="n_news_li_con">
							<div class="n_news_li_name">{{item.title}}</div>
							<div class="n_news_li_js">
								{{item.description}}
							</div>
							<div class="n_news_li_more">查看更多 +</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<foot></foot>
	</div>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue'
	import foot from '@/components/foot.vue'
	import breadNav from '@/components/breadNav.vue'
	export default {
		components: {
			NavigationBar,
			foot,
			breadNav

		},
		data() {
			return {
				tabList: ['企业新闻', '行业新闻'],
				id: '',
				content: '',
				dates: {},
				category_id: '',
				list: []
			}
		},
		mounted() {
			console.log(this.$route)
			this.id = this.$route.query.id;
			this.category_id = this.$route.query.category_id
			if (!this.category_id) {
				this.getData()
			} else {
				this.getList()
			}
		},
		methods: {
			tabClick(id) {
				this.category_id = id;
				this.id = '';
				this.getList()
			},
			del(id) {
				this.id = id;
				this.category_id = '';
				this.getData()
			},
			getList() {
				let _this = this;
				let urls =
					"api/article/index?category_id=" + _this.category_id
				_this.post(urls, res => {
					_this.list = res.data.list;
					_this.tabList = res.data.category_list
					console.log(_this.list)

				})
			},
			getData() {
				let _this = this;
				let urls =
					"api/article/detail?id=" + _this.id
				_this.post(urls, res => {
					console.log(res.data)
					_this.dates = res.data.info;
					_this.content = res.data.info.content;
					_this.tabList = res.data.category_list
				})
			},
		}
	}
</script>

<style scoped>
	.n_news_ul {
	    margin-bottom: 50px;
	}
	.n_news_li {
	    padding: 0 35px;
	    background-color: #ffffff;
	    height: 180px;
	    width: 100%;
	    -webkit-transition: all 0.3s;
	    -moz-transition: all 0.3s;
	    -ms-transition: all 0.3s;
	    -o-transition: all 0.3s;
	    transition: all 0.3s;
	}
	.n_news_li_time {
	    text-align: right;
	    width: 110px;
	    margin-top: 55px;
	}
	.n_news_li_nian {
	    font-size: 18px;
	    color: #d2d2d2;
	    -webkit-transition: all 0.3s;
	    -moz-transition: all 0.3s;
	    -ms-transition: all 0.3s;
	    -o-transition: all 0.3s;
	    transition: all 0.3s;
	}
	.n_news_li_yue {
	    font-size: 34px;
	    font-weight: bolder;
	    color: #d2d2d2;
	    -webkit-transition: all 0.3s;
	    -moz-transition: all 0.3s;
	    -ms-transition: all 0.3s;
	    -o-transition: all 0.3s;
	    transition: all 0.3s;
	}
	.n_news_ul li:hover .n_news_li_nian,
	.n_news_ul li:hover .n_news_li_yue,
	.n_news_ul li:hover .n_news_li_name {
		color: #ca222a;
	}
	.n_news_li_xian {
	    margin-top: 8px;
	    width: 37px;
	    height: 2px;
	    float: right;
	    background-color: #d2d2d2;
	}
	.n_news_li_con {

	    width: 950px;
	    margin-left: 65px;
		text-align: left;
	}
	.n_news_li_name {
	    margin-top: 35px;
	    font-size: 22px;
	    font-weight: bold;
	    color: #555;
	    -webkit-transition: all 0.4s;
	    -moz-transition: all 0.4s;
	    -ms-transition: all 0.4s;
	    -o-transition: all 0.4s;
	    transition: all 0.4s;
	}
	.n_news_li_touch_time {
	    display: none;
	}
	.n_news_li_js {
	    margin-top: 11px;
	    line-height: 28px;
	    font-size: 14px;
	    color: #898989;
	}
	.n_news_li_more {
	    margin-top: 20px;
	    font-size: 14px;
	    color: #898989;
	}
	.banner {
		background: url('../../../public/img/top1.jpg');
		width: 100%;
		height: 340px;
	}

	.banner_name {
		padding-top: 150px;
		font-size: 44px;
		color: #474747;
		text-align: left;
	}

	.about_con {
		margin: 30px auto;
		text-align: left;
	}

	.about_con img {
		/* width: 100%; */
	}

	.about_con p {
		font-size: 15px;
		line-height: 1.6;
		color: #525252;
		margin-bottom: 25px;
	}

	.newshow_name {
		font-size: 28px;
		color: #333333;
		font-weight: bold;
		line-height: 30px;
		margin-top: 50px;
		text-align: left;
	}

	.newshow_zx {
		margin-top: 20px;
		text-align: left;
	}

	.newshow_zx div {
		 font-size: 14px;
		color: #999999;
	}

	.newshow_follow {
		margin-right: 20px;
	}
</style>