<template>
	<div class="nav">
		<!-- :class="[{'active':index==currentIndex},'nav-item']" -->
		<div class="leftAndRight nav-c">
			<img src="../../public/img/logo.png" />
			<div @click="toPage(index)" v-for="(nav,index) in navs" :key="index">{{nav}}</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'NavigationBar',
		data() {
			return {
				currentIndex: 0,
				navs: ['网站首页', '走进企业', '热点资讯', '产品展示', '学习天地', '下载中心', '公益之路', '联系我们']
			}
		},
		methods: {
			toPage(index) {
				let url = ''
				let id=0
				this.currentIndex = index;
				if (index == 0) {
					this.$router.push({
						path: '/',
					})
					return
				} else if (index == 1) {
					url = 'qiye'
					id=2
				} else if (index == 2) {
					this.$router.push({ name: 'xiwen', query: { category_id: 6 }});
					return;
				} else if (index == 3) {
					this.$router.push({ name: 'chanpin', query: { category_id: 5 }});
					return;
				} else if (index == 4) {
					url = 'xuexi'
					id=21
				} else if (index == 5) {
					url = 'xiazai'
				} else if (index == 6) {
					url = 'gongyi'
				} else if (index == 7) {
					url = 'lianxi'
				}
				this.$router.push({ name: url, query: { id: id }});
				// this.$router.push({
				// 	path: url,
					
				// })

			}
		}
	}
</script>

<style>
	.nav {
		display: flex;
		height: 100px;
		background: #fff;
		color: #5f5f5f;
		font-size: 15px;
		justify-content: space-between;
		align-items: center;
		/* padding:0 20px; */
		width: 100%;
	}

	.nav-c {
		width: 60%;
		margin: auto;
	}

	.nav-item {
		margin-right: 20px;
		font-size: 15px;
		text-decoration: none;
	}

	.nav-c div:hover {
		color: #c9151e;
		cursor: pointer;
	}
</style>