<template>
	<div>
		<NavigationBar></NavigationBar>
		<div class="content">
			<el-carousel :interval="interval" arrow="always" indicator-position="outside">
				<el-carousel-item v-for="(item, index) in items" :key="index">
					<img :src="item.image" alt="" style="width: 100%;">
				</el-carousel-item>
			</el-carousel>
			<div class="about_title">走进企业</div>
			<div class="about_xian"></div>
			<div class="about_js">
				湖南村里村外农业开发有限公司成立于2013年，座落于美丽的星城——长沙，是一家致力于发展农业科技的企业，致力于丰富农业生产者和消费者们的生活，保障农业生产的过程。以领先的业务规模和广泛的专业知识为种植...
			</div>
			<ul class="cardList leftAndRight">
				<li class="cardItem" @click="toPage(2,2)">
					<div class="cardItemBg" style="background:rgba(0,0,0,0.5);"> <span
							style="color: #ffffff;">企业简介</span> </div>
				</li>
				<li class="cardItem cardItem1" @click="toPage(4,2)">
					<div class="cardItemBg" style="background:rgba(0,0,0,0.5);"> <span
							style="color: #ffffff;">组织架构</span> </div>
				</li>
				<li class="cardItem cardItem2" @click="toPage(5,2)">
					<div class="cardItemBg" style="background:rgba(0,0,0,0.5);"> <span
							style="color: #ffffff;">企业文化</span> </div>
				</li>
				<li class="cardItem cardItem3">
					<div class="cardItemBg" style="background:rgba(0,0,0,0.5);"> <span
							style="color: #ffffff;">资质荣誉</span> </div>
				</li>
			</ul>
			<a class="about_more" @click="toPage(2,2)">MORE</a>
			<div class="chanpin">
				<div class="cardList">
					<div class="chanpin_title">产品展示</div>
					<div class="chanpin_xian"></div>
					<div class="chanpin_list">
						<ul class="am-slides">
							<li class="chanpin_item" @click="toPage(product.id,3)">
								<div class="chanpin_img">
									<img :src="product.image" />
								</div>
								<div class="chanpin_con">
									<div class="chanpin_name">{{product.title}}</div>
									<div class="chanpin_js">{{product.description}}</div>
								</div>

							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="about_title">热点资讯</div>
			<div class="about_xian"></div>
			<div class="news_list divColumnCenter">
				<div class="news_list_left" @click="toPage(news[0].id)">
					<a>
						<div class="news_list_left_img">
							<img :src="news[0].image" />
						</div>

						<div class="news_list_left_con">
							<div class="news_list_left_time">{{news[0].year}}-{{news[0].mday}}</div>
							<div class="news_list_left_title">{{news[0].title}}</div>
						</div>
					</a>
				</div>
				<div class="news_list_right">
					<ul>
						<li v-for="(item, index) in news" :key="index" @click="toPage(item.id)">
							<a class="divColumnCenter">
								<div class="news_right_li divColumnCenter">
									<div class="news_right_li_time">
										<div class="news_right_li_nian">{{item.year}}</div>
										<div class="news_right_li_yue">{{item.mday}}</div>
									</div>
									<div class="news_right_li_con">
										<div class="news_right_li_name">{{item.title}}</div>
										<div class="news_right_li_js">
											{{item.description}}
										</div>
									</div>
								</div>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<a class="about_more" @click="toPage1(6,1)">MORE</a>

			<div class="about_title">学习天地</div>
			<div class="about_xian"></div>
			<div class="pinpai_list divColumnCenter">
				<ul class="leftAndRight">
					<li @click="toPage(21,4)">
						<a>
							<div class="pinpai_li">
								<img src="../../../public/img/xx.jpg" class="pinpai_li_img" />
								<div class="pinpai_li_posi">
									<div class="pinpai_li_posi_name">膳食课堂</div>
									<div class="pinpai_li_posi_xian"></div>
									<div class="pinpai_li_posi_js">随着生活水平的提高，居民对健康饮食越来越看重，我们将全方位给您带来一场···</div>
									<div class="pinpai_li_posi_more">查看详情</div>
								</div>
							</div>
						</a>
					</li>
					<li @click="toPage(19,4)">
						<a>
							<div class="pinpai_li">
								<img src="../../../public/img/xx1.jpg" class="pinpai_li_img" />
								<div class="pinpai_li_posi">
									<div class="pinpai_li_posi_name">食神课堂</div>
									<div class="pinpai_li_posi_xian"></div>
									<div class="pinpai_li_posi_js">最优质的食材，需要配以最正宗的料理方法。在村里村外，我们不但提供绿色天···</div>
									<div class="pinpai_li_posi_more">查看详情</div>
								</div>
							</div>
						</a>
					</li>
				</ul>
			</div>
			<a class="about_more" @click="toPage(21,4)">MORE</a>
			<foot></foot>
			<!-- <router-view></router-view> -->
		</div>
	</div>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue'
	import foot from '@/components/foot.vue'
	export default {
		name: 'App',
		components: {
			NavigationBar,
			foot

		},
		data() {
			return {
				interval: 50,
				items: [],
				date: {},
				product: {},
				news: []
			}
		},

		created() {
			this.getData()
		},
		methods: {
			getData() {
				let _this = this;
				let urls =
					"api/index/index"
				_this.post(urls, res => {
					console.log(res.data)
					_this.date = res.data;
					_this.items = res.data.slide;
					_this.product = res.data.product;
					_this.news = res.data.news;
				})
			},
			toPage(id, type) {
				if (type == 2) { //走进企业
					this.$router.push({
						name: 'qiye',
						query: {
							id: id
						}
					});
					return;
				}
				if (type == 3) { //产品
					this.$router.push({
						name: 'chanpin',
						query: {
							id: id
						}
					});
					return;
				}
				if (type == 4) { //学习
					this.$router.push({
						name: 'xuexi',
						query: {
							id: id
						}
					});
					return;
				}
				this.$router.push({
					name: 'xiwen',
					query: {
						id: id
					}
				});
			},
			toPage1(id,type){
				if(type==1){
					this.$router.push({ name: 'xiwen', query: { category_id: 6 }});
				}
			}
		}
	}
</script>

<style scoped>
	/deep/.el-carousel__container {
		height: 727px;
	}

	.carousel-item-text {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.5);
		color: #fff;
		padding: 16px;
		box-sizing: border-box;
	}

	/* 	.el-carousel__item img {
		width: 100%;
		height: 500px;
	} */

	.about_title {
		font-size: 30px;
		color: #464545;
		text-align: center;
		font-weight: bolder;
		margin-top: 73px;
	}

	.about_xian {
		width: 65px;
		height: 3px;
		background-color: #c9151e;
		margin: 20px auto;
	}

	.about_js {
		line-height: 30px;
		text-align: center;
		width: 805px;
		margin: 0 auto;
		font-size: 15px;
		color: #787878;
	}

	.cardItem {
		position: relative;
		width: 198px;
		height: 247px;
		background: url('../../../public/img/card1.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;

	}

	.cardItem1 {
		background: url('../../../public/img/card2.png');
	}

	.cardItem2 {
		background: url('../../../public/img/card3.png');
	}

	.cardItem3 {
		background: url('../../../public/img/card4.png');
	}

	.cardList {
		width: 1200px;
		margin: auto;
		margin-top: 70px;
	}

	.cardItemBg {
		text-align: center;
		opacity: 1;
		width: 100%;
		height: 100%;
		position: absolute;
		-webkit-transition: all 0.4s;
		-moz-transition: all 0.4s;
		-ms-transition: all 0.4s;
		-o-transition: all 0.4s;
		transition: all 0.4s;
	}

	.cardItemBg span {
		font-weight: 300;
		display: block;
		width: 20px;
		line-height: 30px;
		margin: 70px auto 0 auto;
		font-size: 24px;
	}

	.about_more {
		display: inline-block;
		width: 93px;
		height: 26px;
		line-height: 26px;
		line-height: 26px;
		text-align: center;
		color: #da2d2d;
		margin: 60px auto 40px auto;
		border: 1px solid #DA2D2D;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-ms-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
		clear: both;
	}

	.about_more:hover {
		color: #fff;
		background-color: #da2d2d;
	}

	.chanpin {
		background: url('../../../public/img/cp.jpg') no-repeat;
		background-size: 100% 70%;
		width: 100%;
		/* height: 600px; */

	}

	.chanpin_title {
		padding-top: 50px;
		font-size: 30px;
		color: #ffffff;
		font-weight: bolder;
		text-align: center;
	}

	.chanpin_xian {
		width: 65px;
		height: 3px;
		margin: 25px auto;
		background: rgba(255, 255, 255, 0.5);
	}

	.chanpin_list {}

	.chanpin_img {
		overflow: hidden;
		width: 1200px;
	}

	.chanpin_img img {
		width: 100%;
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
		-webkit-transition: all 0.8s;
		-moz-transition: all 0.8s;
		-ms-transition: all 0.8s;
		-o-transition: all 0.8s;
		transition: all 0.8s;

	}

	.chanpin_item:hover img {
		transform: scale(1.2);
	}

	.chanpin_con {
		background-color: #f6f6f6;
		padding-bottom: 50px;
		-webkit-transition: all 0.4s;
		-moz-transition: all 0.4s;
		-ms-transition: all 0.4s;
		-o-transition: all 0.4s;
		transition: all 0.4s;
		color: #555555;
	}


	.chanpin_name {
		padding-top: 50px;
		font-size: 26px;
		text-align: center;
		-webkit-transition: all 0.4s;
		-moz-transition: all 0.4s;
		-ms-transition: all 0.4s;
		-o-transition: all 0.4s;
		transition: all 0.4s;
	}

	.chanpin_item:hover .chanpin_con {
		color: #fff;
		background-color: #da2d2d;
	}

	.chanpin_js {
		width: 270px;
		margin: 8px auto 0 auto;
		text-align: center;
		font-size: 14px;
		line-height: 28px;
		-webkit-transition: all 0.4s;
		-moz-transition: all 0.4s;
		-ms-transition: all 0.4s;
		-o-transition: all 0.4s;
		transition: all 0.4s;
	}

	.news_list {
		width: 1200px;
		margin: auto;
	}

	.news_list_left {
		width: 524px;
		height: 393px;
		float: left;
		position: relative;
	}

	.news_list_left_img {
		width: 524px;
		height: 308px;
		overflow: hidden;
	}

	.news_list_left_img img {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-o-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		-webkit-transition: all 1s;
		-moz-transition: all 1s;
		-ms-transition: all 1s;
		-o-transition: all 1s;
		transition: all 1s;
	}

	.news_list_left_img img:hover {
		transform: scale(1.2);
	}

	.news_list_left_con {
		/* padding: 0 24px; */
		width: 100%;
		height: 85px;
		position: absolute;
		right: 0;
		bottom: 0;
		background-color: #ca222a;
	}

	.news_list_left_time {
		font-size: 18px;
		color: #ffffff;
		margin-top: 15px;
	}

	.news_list_left_title {
		font-size: 16px;
		line-height: 26px;
		margin-top: 10px;
		color: #ffffff;
	}

	.news_list_right {
		float: left;
		width: 660px;
		margin-left: 16px;
	}

	.news_list_right li {
		margin-bottom: 13px;
		-webkit-transition: all 0.4s;
		-moz-transition: all 0.4s;
		-ms-transition: all 0.4s;
		-o-transition: all 0.4s;
		transition: all 0.4s;
	}

	.news_right_li {
		background-color: #f4f4f4;
		width: 660px;
		height: 122px;
	}

	.news_right_li_time {
		margin-top: 30px;
		float: left;
		margin-left: 15px;
		width: 100px;
		text-align: right;
	}

	.news_right_li_nian {
		font-size: 14px;
		color: #c8c8c8;
		-webkit-transition: all 0.4s;
		-moz-transition: all 0.4s;
		-ms-transition: all 0.4s;
		-o-transition: all 0.4s;
		transition: all 0.4s;
	}

	.news_right_li_yue {
		font-size: 34px;
		color: #c8c8c8;
		-webkit-transition: all 0.4s;
		-moz-transition: all 0.4s;
		-ms-transition: all 0.4s;
		-o-transition: all 0.4s;
		transition: all 0.4s;
	}

	.news_right_li_con {
		margin-left: 30px;
		width: 470px;
		text-align: left;
		float: left;
	}

	.news_right_li_name {
		font-size: 16px;
		color: #666666;
		margin-top: 23px;
		text-align: left;
	}

	.news_right_li_js {
		margin-top: 12px;
		line-height: 22px;
		font-size: 13px;
		color: #666666;
	}

	.news_right_li:hover .news_right_li_nian,
	.news_right_li:hover .news_right_li_yue {
		color: #ca222a;
	}

	.pinpai_list {
		width: 1200px;
		margin: auto;
	}

	.pinpai_list li {
		position: relative;
		float: left;
		margin-right: 10px;
		width: 595px;
		height: 311px;
		overflow: hidden;
	}

	.pinpai_list li img {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-o-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		-webkit-transition: all 1s;
		-moz-transition: all 1s;
		-ms-transition: all 1s;
		-o-transition: all 1s;
		transition: all 1s;
	}

	.pinpai_list li img:hover {
		transform: scale(1.2);
	}

	.pinpai_li_posi {
		padding: 0 20px;
		position: absolute;
		right: 28px;
		top: 32px;
		width: 220px;
		height: 242px;
		background: rgba(255, 255, 255, 0.85);
	}

	.pinpai_li_posi_name {
		font-size: 26px;
		color: #333333;
		font-weight: bolder;
		margin-top: 22px;
	}

	.pinpai_li_posi_xian {
		margin: 14px 0;
		width: 57px;
		height: 2px;
		background-color: #b8b8b9;
		-webkit-transition: all 0.4s;
		-moz-transition: all 0.4s;
		-ms-transition: all 0.4s;
		-o-transition: all 0.4s;
		transition: all 0.4s;
	}

	.pinpai_list li:hover .pinpai_li_posi_xian {
		background-color: #DA2D2D;
		width: 100%;
	}

	.pinpai_li_posi_js {
		margin-bottom: 10px;
		font-size: 13px;
		color: #787878;
		line-height: 22px;
	}

	.pinpai_li_posi_more {
		width: 134px;
		height: 30px;
		margin: 0 auto;
		border-radius: 20px;
		background-color: #c9151e;
		text-align: center;
		line-height: 30px;
		color: #ffffff;
		font-size: 14px;
	}
</style>